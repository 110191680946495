type TagSearchAnimationState = 'expand' | 'collapse'

export default class TagSearchAnimationController {
  constructor(
    private $container: HTMLElement,
    private prefix?: string,
  ) {
    const defaultState = $container.getAttribute(this.datasetKeys.state) || 'expand'
    this.state = defaultState === 'expand' ? 'expand' : 'collapse'
    this.$panel = $container.querySelector(`[${this.datasetKeys.panel}]`) as HTMLElement
    this._setPanelWidth()
    this._setContainerHeight()
    this._setEventListener()
  }

  public state: TagSearchAnimationState = 'collapse'

  public $panel: HTMLElement

  get datasetKeys(): {
    // container: string,
    state: string,
    action: string,
    panel: string,
  } {
    const prefix = typeof (this.prefix) !== 'undefined' ? `${this.prefix}-` : ''
    return {
      state: `data-${prefix}tag-search-state`,
      action: `data-${prefix}tag-search-action`,
      panel: `data-${prefix}tag-search-panel`,
    }
  }

  _setEventListener = (): void => {
    const $triggers = (() => {
      const nodeList = this.$container.querySelectorAll(
        `[${this.datasetKeys.action}]`,
      )
      return Array.from(nodeList).map((e) => e as HTMLAnchorElement)
    })()

    $triggers.forEach($trigger => {
      const value = $trigger.getAttribute(this.datasetKeys.action) || ''
      if (value === 'toggle') {
        $trigger.addEventListener('click', this.toggle.bind(this))
      } else if (value === 'open') {
        $trigger.addEventListener('click', this.open.bind(this))
      } else if (value === 'close') {
        $trigger.addEventListener('click', this.close.bind(this))
      }
    })
    window.addEventListener('resize', () => {
      this._setPanelWidth()
        this._setContainerHeight()
      },
    )
  }

  _updateDOM = (): void => {
    this.$container.setAttribute(this.datasetKeys.state, String(this.state))
  }

  _updateState = (state: TagSearchAnimationState): void => {
    this.state = state
    this._updateDOM()
  }

  _setContainerHeight = (): void => {
    const baseHeight = window.innerWidth < 768 ? 38 : 50
    const { scrollHeight } = this.$panel
    this.$container.style.height = `${baseHeight + scrollHeight}px`
  }

  _setPanelWidth = (): void => {
    const { innerWidth } = window
    const width = innerWidth < 768 ? innerWidth - 32 : 620
    this.$panel.style.width = `${width}px`
  }

  open = (e?: Event): void => {
    e?.preventDefault()
    this._updateState('expand')
  }

  close = (e?: Event): void => {
    e?.preventDefault()
    this._updateState('collapse')
  }

  toggle = (e?: Event): void => {
    if (this.state === 'expand') {
      this.close(e)
    } else {
      this.open(e)
    }
  }
}
