import ScrollMagic from 'scrollmagic'

/* pageup */
window.addEventListener('load', () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
  const controller = new ScrollMagic.Controller()
  const $pageupBtn = document.querySelector('.l-pageup-btn')
  const $footer = document.querySelector('.l-footer')
  if ($pageupBtn === null || $footer === null) return

  // eslint-disable-next-line no-new,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
  new ScrollMagic.Scene({
    triggerElement: 'body',
    triggerHook: 0,
    offset: 500,
  })
    .setClassToggle($pageupBtn, '__visible')
    .addTo(controller)

  // eslint-disable-next-line no-new,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
  new ScrollMagic.Scene({
    triggerElement: $footer,
    triggerHook: 1,
    offset: matchMedia('screen and (max-width: 768px)').matches ? 0 : 0,
    reverse: true,
  })
    .setClassToggle($pageupBtn, '__static')
    .addTo(controller)
})
