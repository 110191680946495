type FormSearchAnimationState = 'expand' | 'collapse'

export default class FormSearchAnimationController {
  constructor(
    private $container: HTMLElement,
    private prefix?: string,
  ) {
    const defaultState = $container.getAttribute(this.datasetKeys.state) || 'expand'
    this.state = defaultState === 'expand' ? 'expand' : 'collapse'
    this._setEventListener()
  }

  public state: FormSearchAnimationState = 'collapse'

  get datasetKeys(): {
    // container: string,
    state: string,
    action: string,
  } {
    const prefix = typeof (this.prefix) !== 'undefined' ? `${this.prefix}-` : ''
    return {
      state: `data-${prefix}form-search-state`,
      action: `data-${prefix}form-search-action`,
    }
  }

  _setEventListener = (): void => {
    const $triggers = (() => {
      const nodeList = this.$container.querySelectorAll(
        `[${this.datasetKeys.action}]`,
      )
      return Array.from(nodeList).map((e) => e as HTMLAnchorElement)
    })()

    $triggers.forEach($trigger => {
      const value = $trigger.getAttribute(this.datasetKeys.action) || ''
      if (value === 'focus') {
        // $trigger.addEventListener('focusin', this.focus.bind(this), true)
        $trigger.addEventListener('focusin', this.focus.bind(this), true)
        // $trigger.addEventListener('focusout', this.unfocus.bind(this), true)
        $trigger.addEventListener('focusout', this.unfocus.bind(this), true)
        this.$container.addEventListener('transitionend', this.transitionEnd.bind(this))
      } else if (value === 'toggle') {
        $trigger.addEventListener('click', this.toggle.bind(this))
      } else if (value === 'open') {
        $trigger.addEventListener('click', this.open.bind(this))
      } else if (value === 'close') {
        $trigger.addEventListener('click', this.close.bind(this))
      }
    })
  }

  _updateDOM = (): void => {
    this.$container.setAttribute(this.datasetKeys.state, String(this.state))
  }

  _updateState = (state: FormSearchAnimationState): void => {
    this.state = state
    this._updateDOM()
  }

  open = (e?: Event): void => {
    e?.preventDefault()
    this.$container.style.zIndex = '1'
    setTimeout(() => {
      this._updateState('expand')
    }, 10)
  }

  close = (e?: Event): void => {
    // e?.preventDefault()
    this._updateState('collapse')
  }

  toggle = (e?: MouseEvent): void => {
    if (this.state === 'expand') {
      this.close(e)
    } else {
      this.open(e)
    }
  }

  focus = (e?: FocusEvent): void => {
    setTimeout(() => {

      this.open(e)
    }, 100)
  }

  unfocus = (e?: FocusEvent): void => {
    setTimeout(() => {

      this.close(e)
    }, 500)
  }

  transitionEnd = (e?: TransitionEvent): void => {
    if (this.state === 'collapse') {
      this.$container.style.zIndex = '0'
    }
  }
}
