type TabState = string

export default class TabController {
  constructor(
    private $container: HTMLElement,
    private prefix?: string,
  ) {
    this.$triggers = (() => {
      const nodeList = this.$container.querySelectorAll(
        `[${this.datasetKeys.action}]`,
      )
      return Array.from(nodeList).map((e) => e as HTMLAnchorElement)
    })()
    const defaultState = this.$container.getAttribute(this.datasetKeys.state) || ''
    this.state = defaultState
    this._setStyle()
    this._setEventListener()
  }

  public state: TabState

  // private $container: HTMLElement

  get datasetKeys(): {
    container: string,
    state: string,
    action: string,
    color: string,
    panel: string,
  } {
    const prefix = typeof (this.prefix) !== 'undefined' ? `${this.prefix}-` : ''
    return {
      container: `data-${prefix}tab-container`,
      state: `data-${prefix}tab-state`,
      action: `data-${prefix}tab-action`,
      color: `data-${prefix}tab-color`,
      panel: `data-${prefix}tab-panel`,
    }
  }

  public $triggers: HTMLAnchorElement[] = []

  _setStyle = (): void => {
    const styleElm = document.createElement('style')
    const actions = this.$triggers.map((e) => e.getAttribute(this.datasetKeys.action))
    const colors = this.$triggers.map((e) => e.getAttribute(this.datasetKeys.color))

    styleElm.innerText = ''

    actions.forEach((e, i) => {
      const color = colors[i]
      if (e === '' || e === null || color === null) return
      styleElm.innerText += `[${this.datasetKeys.state}] [${this.datasetKeys.action}="${e}"] { border-color: ${color} !important; } `
      styleElm.innerText += `[${this.datasetKeys.state}="${e}"] [${this.datasetKeys.action}="${e}"] { background-color: ${color} !important; color: white !important; } `
      styleElm.innerText += `[${this.datasetKeys.state}="${e}"] [${this.datasetKeys.panel}="${e}"] { display: block !important; } `
    })
    document.querySelector('head')?.insertAdjacentElement('beforeend', styleElm)
  }

  _setEventListener = (): void => {

    this.$triggers.forEach($trigger => {
      const value = $trigger.getAttribute(this.datasetKeys.action) || ''
      $trigger.addEventListener('click', this._updateState.bind(this, value))
    })
  }

  _updateDOM = (): void => {
    this.$container.setAttribute(this.datasetKeys.state, `${this.state}`)
  }

  _updateState = (value: string, e?: Event): void => {
    if (e) e.preventDefault()
    if (this.state === value) return
    this.state = value
    this._updateDOM()
  }
}
