type AccordionState = 'expand' | 'collapse'

export default class AccordionController {
  constructor(
    private $container: HTMLElement,
    private prefix?: string,
  ) {
    // this.$container = document.querySelector(`[${this.datasetKeys.container}]`) as HTMLElement
    const defaultState = $container.getAttribute(this.datasetKeys.state) || 'expand'
    this.state = defaultState === 'expand' ? 'expand' : 'collapse'
    this.$panel = $container.querySelector(`[${this.datasetKeys.panel}]`) as HTMLElement
    this._setPanelHeight()
    this._setEventListener()
  }

  public state: AccordionState = 'expand'

  public $panel: HTMLElement

  get datasetKeys(): {
    // container: string,
    state: string,
    action: string,
    panel: string,
  } {
    const prefix = typeof (this.prefix) !== 'undefined' ? `${this.prefix}-` : ''
    return {
      // container: `data-${prefix}menu-container`,
      state: `data-${prefix}accordion-state`,
      action: `data-${prefix}accordion-action`,
      panel: `data-${prefix}accordion-panel`,
    }
  }

  _setEventListener = (): void => {
    const $triggers = (() => {
      const nodeList = this.$container.querySelectorAll(
        `[${this.datasetKeys.action}]`,
      )
      return Array.from(nodeList).map((e) => e as HTMLAnchorElement)
    })()

    $triggers.forEach($trigger => {
      const value = $trigger.getAttribute(this.datasetKeys.action) || ''
      if (value === 'toggle') {
        $trigger.addEventListener('click', this.toggle.bind(this))
      } else if (value === 'open') {
        $trigger.addEventListener('click', this.open.bind(this))
      } else if (value === 'close') {
        $trigger.addEventListener('click', this.close.bind(this))
      }
    })

    window.addEventListener('resize', this._setPanelHeight.bind(this))
  }

  _updateDOM = (): void => {
    this.$container.setAttribute(this.datasetKeys.state, String(this.state))
  }

  _updateState = (state: AccordionState): void => {
    this.state = state
    this._updateDOM()
  }

  _setPanelHeight = (): void => {
    if (window.innerWidth < 768) {
      const { scrollHeight } = this.$panel
      this.$panel.style.height = `${scrollHeight}px`
    } else {
      this.$panel.style.height = 'auto'
    }
  }

  open = (e?: Event): void => {
    e?.preventDefault()
    this._updateState('expand')
  }

  close = (e?: Event): void => {
    e?.preventDefault()
    this._updateState('collapse')
  }

  toggle = (e?: Event): void => {
    if (this.state === 'expand') {
      this.close(e)
    } else {
      this.open(e)
    }
  }
}
