type MenuState = boolean
export default class MenuController {
  constructor(
    private $container: HTMLElement,
    private prefix?: string,
  ) {
    this._setEventListener()
    // this.$container = document.querySelector(`[${this.datasetKeys.container}]`) as HTMLElement
    const defaultState = this.$container.getAttribute(this.datasetKeys.state) || 'false'
    this.state = defaultState === 'true'
  }

  public state: MenuState = false
  //
  // public $container: HTMLElement

  get datasetKeys(): {
    // container: string,
    state: string,
    action: string,
  } {
    const prefix = typeof (this.prefix) !== 'undefined' ? `${this.prefix}-` : ''
    return {
      // container: `data-${prefix}menu-container`,
      state: `data-${prefix}menu-state`,
      action: `data-${prefix}menu-action`,
    }
  }

  _setEventListener = (): void => {
    const $triggers = (() => {
      const nodeList = this.$container.querySelectorAll(
        `[${this.datasetKeys.action}]`,
      )
      return Array.from(nodeList).map((e) => e as HTMLAnchorElement)
    })()

    $triggers.forEach($trigger => {
      const value = $trigger.getAttribute(this.datasetKeys.action) || ''
      if (value === 'toggle') {
        $trigger.addEventListener('click', this.toggle.bind(this))
      } else if (value === 'open') {
        $trigger.addEventListener('click', this.open.bind(this))
      } else if (value === 'close') {
        $trigger.addEventListener('click', this.close.bind(this))
      }
    })
  }

  _updateDOM = (): void => {
    this.$container.setAttribute(this.datasetKeys.state, String(this.state))
  }

  _updateState = (state: boolean): void => {
    this.state = state
    this._updateDOM()
  }

  open = (e?: Event): void => {
    e?.preventDefault()
    this._updateState(true)
  }

  close = (e?: Event): void => {
    e?.preventDefault()
    this._updateState(false)
  }

  toggle = (e?: Event): void => {
    e?.preventDefault()
    this._updateState(!this.state)
  }
}
