import jump from 'jump.js'

const handleClick = ({
                       hash,
                       isAnim = true,
                     }: {
  hash: string
  isAnim?: boolean
}): void => {
  const $elm: HTMLElement | null = document.querySelector(hash)
  if ($elm) {
    jump(hash, {
      duration: isAnim ? 500 : 0,
    })
  }
}

/* common */
window.addEventListener('load', () => {
  const $anchorNodes: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(
    'a[href^="#"]',
  )
  // const $anchors: Array<HTMLAnchorElement> = Array.prototype.slice.call(
  //   $anchorNodes,
  //   0,
  // )
  const $anchors: Array<HTMLAnchorElement> = Array.from($anchorNodes)
  const { hash } = window.location

  if (hash.includes('#anchor-')) {
    const anchorHash = hash.replace('#anchor-', '#')
    handleClick({ hash: anchorHash })
  }

  $anchors.forEach(anchor => {
    if (anchor.hash === '' || anchor.hash === '#') return

    anchor.addEventListener('click', e => {
      e.preventDefault()
      handleClick({
        hash: anchor.hash,
      })
    })
  })
})
