import '../scss/main.scss'
import './_jump.setting'
import './_scrollmagic.setting'
import TabController from './modules/TabController'
import MenuController from './modules/MenuController'
import AccordionController from './modules/AccordionController'
import TagSearchAnimationController from './modules/TagSearchAnimationController'
import FormSearchAnimationController from './modules/FormSearchAnimationController'

window.addEventListener('load', () => {
  document.body.classList.add('__loaded')

  {
    /* sp-menu */
    const $container = document.querySelector(`[data-sp-menu-container]`) as HTMLElement
    if ($container !== null) {
      const spMenu = new MenuController($container, 'sp')
    }
  }

  {
    /* tab */
    const $container = document.querySelector(`[data-tab-container]`) as HTMLElement
    if ($container !== null) {
      const tab = new TabController($container)
    }
    // const tabs: TabController[] = (() => {
    //   const nodeList = document.querySelectorAll('[data-tab-container]')
    //   return Array.from(nodeList)
    //     .map(node => new TabController(node as HTMLElement))
    // })()
    // console.log(tabs)

    // const { hash } = window.location
    // if (hash.includes('#tabindex-') && tabs.length !== 0) {
    //   let tabindex = ''
    //   if (hash.includes('#anchor-')) {
    //     tabindex = hash.replace(/#anchor-.*?#tabindex-/, '')
    //   } else {
    //     tabindex = hash.replace('#tabindex-', '')
    //   }
    //   tabs[0].updateState(tabindex)
    // }
  }

  {
    /* accordion */
    const $accordions: AccordionController[] = (() => {
      const nodeList = document.querySelectorAll('[data-accordion-container]')
      return Array.from(nodeList)
        .map(node => new AccordionController(node as HTMLElement))
    })()
  }

  {
    /* form-search */
    const $formSearches: FormSearchAnimationController[] = (() => {
      const nodeList = document.querySelectorAll('[data-form-search-container]')
      return Array.from(nodeList)
        .map(node => new FormSearchAnimationController(node as HTMLElement))
    })()

    /* tag-search */
    const $tagSearches: TagSearchAnimationController[] = (() => {
      const nodeList = document.querySelectorAll('[data-tag-search-container]')
      return Array.from(nodeList)
        .map(node => new TagSearchAnimationController(node as HTMLElement))
    })()
  }
})
